import React from "react";
import { graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import ContactSidebar from "../components/ContactSidebar";
import AdresseSidebar from "../components/AdresseSidebar";
import AvantApresBlock from "../components/AvantApresBlock";
import KeybodyBlock from "../components/KeybodyBlock";
import VideoBlock from "../components/VideoBlock";
import NewsBlock from "../components/NewsBlock";
import ASavoir from "../components/ASavoir";
import Layout from "../components/layout";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Social from "../components/Social";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { allMarkdownRemark } = data; // data.markdownRemark holds our post data

  // filter current page fron other page array

  const mediaQueries = {
    phone: "@media only screen and (max-width: 991px)",
  };

  const Wrapper = g.div({
    maxWidth: `1000px`,
    margin: `0 auto 2.5em`,
  });

  const Title = g.h1({
    textAlign: `center`,
    fontSize: `36px`,
    color: `#3c4d68`,
    maxWidth: `700px`,
    margin: `1.8em auto .8em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `49px`,
    "@media(max-width: 767px)": {
      fontSize: `28px`,
      lineHeight: `32px`,
      margin: `1.5em auto 1em`,
    },
  });

  const Card = g.section({
    backgroundColor: `white`,
    marginBottom: `2em`,
    borderRadius: `6px`,
    border: `1px solid rgba(73,105,126,0.08)`,
    boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
    transition: `all 0.15s ease-in-out`,
    ":hover": {
      border: `1px solid rgba(73,105,126,0.18)`,
      boxShadow: `0 1px 10px rgba(0,0,0,.26)`,
      transition: `all 0.15s ease-in-out`,
    },
  });

  const Date = g.div({
    fontSize: `.7em`,
    fontWeight: `600`,
    letterSpacing: `0.12em`,
    margin: `1em 0 0em 0`,
    color: `#1f1f1f`,
    opacity: `.75`,
    [mediaQueries.phone]: {
      marginLeft: `1.2em`,
    },
  });

  const H2 = g.h2({
    fontSize: `1.25em`,
    fontWeight: `500`,
    lineHeight: `1.4em`,
    margin: `0 1em .3em 0`,
    [mediaQueries.phone]: {
      marginLeft: `.7em`,
    },
  });

  const P = g.p({
    color: `#1f1f1f`,
    margin: `0 .5em 1em 0`,
    [mediaQueries.phone]: {
      marginLeft: `.8em`,
    },
  });

  const Plus = g.div({
    float: `right`,
    color: `#3c4d68`,
    margin: `0 1.5em .5em 0`,
    fontSize: `.85em`,
    fontWeight: `500`,
  });

  css.global(".gatsby-resp-image-figcaption", {
    textAlign: "center",
    fontStyle: `italic`,
    fontSize: `.85em`,
    lineHeight: `1.45em`,
    marginTop: `.5em`,
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>Actualités</title>
          <meta
            name="description"
            content="Actualités du cabinet du Dr Kron, chirurgien esthétique qualifié en chirurgie plastique et reconstructrice à Paris concernant les traitements esthétiques du visage et du corps."
          />
          <html lang="fr" />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
"itemListElement": [{
  "@type": "ListItem",
  "position": 1,
  "name": "Actualités",
  "item": "https://www.dr-kron.com/actualites/"
}]
      }
    `}</script>
        </Helmet>
        <Menu pathEn="/en/" />

        <Wrapper>
          <article>
            <Container css={{ padding: `0 15px !important` }}>
              <Row>
                <Col>
                  <div className="page-post">
                    <Title>Actualités</Title>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={{ xs: 12 / 12, lg: 69 / 100 }}>
                  {allMarkdownRemark.edges.map(({ node }) => {
                    const {
                      title,
                      shortTitle,
                      excerpt,
                      dateDisplay,
                      path,
                      vignette,
                    } = node.frontmatter;
                    return (
                      <Link to={path}>
                        <Card>
                          <Row>
                            <Col span={{ xs: 12 / 12, sm: 4 / 10 }}>
                              <Img
                                fluid={vignette.childImageSharp.fluid}
                                alt={title}
                                css={{
                                  margin: `1em 0 1em 1em`,
                                  [mediaQueries.phone]: {
                                    margin: `0`,
                                  },
                                }}
                              />
                            </Col>
                            <Col span={{ xs: 12 / 12, sm: 6 / 10 }}>
                              <Date>{dateDisplay}</Date>
                              <H2>{shortTitle}</H2>
                              <P>{excerpt}</P>
                              <Plus>> En savoir plus</Plus>
                            </Col>
                          </Row>
                        </Card>
                      </Link>
                    );
                  })}
                  <Social path="/actualites/" title="Actualités" />
                </Col>
                <Col span={{ xs: 12 / 12, lg: 31 / 100 }}>
                  <aside>
                    <ContactSidebar about="les actualités du cabinet" />
                    <AdresseSidebar />
                    <AvantApresBlock />
                    <KeybodyBlock />
                    <VideoBlock />
                    <NewsBlock />
                    <ASavoir />
                  </aside>
                </Col>
              </Row>
            </Container>
          </article>
        </Wrapper>
        <Footer />
      </g.Div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Actualites {
    allMarkdownRemark(
      filter: { frontmatter: { tags: { eq: "actualites" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            shortTitle
            path
            excerpt
            date
            dateDisplay
            vignette {
              childImageSharp {
                fluid(maxWidth: 952) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          html
        }
      }
    }
  }
`;
